import { format } from "date-fns";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatAsCurrency } from "../formatAsCurrency";
import * as React from "react";
import CustomAmountTooltip from "./customAmountTooltip";
import { groupBy } from "lodash";

export type ChartMargins = {
  top: number;
  right: number;
  left: number;
  bottom: number;
};

const dateFormatter = (date: Date) =>
  date.getMonth() === 0 ? format(date, "yy") : format(date, "MMM");

export type AccountChartProps = {
  assetData: string;
  liabilityData: string;
  label: string;
  width?: number;
  height?: number;
  margin?: ChartMargins;
};

const AccountChart = ({
  assetData = "[]",
  liabilityData = "[]",
  width = 1000,
  height = 600,
  margin = { top: 5, right: 5, left: 5, bottom: 5 },
}: AccountChartProps) => {
  const parseData = (data: string) =>
    JSON.parse(data).map(({ date, value }) => ({
      date: new Date(date),
      value,
    }));
  const parsedAssetData = groupBy(parseData(assetData), "date");
  const parsedLiabilityData = groupBy(parseData(liabilityData), "date");
  const hasAssets = Object.keys(parsedAssetData).length > 0;
  const hasLiabilities = Object.keys(parsedLiabilityData).length > 0;
  const chartData = [];
  for (const date in parsedAssetData) {
    chartData[date] = {
      date: new Date(date),
      assetValue: parsedAssetData[date][0].value,
    };
  }
  for (const date in parsedLiabilityData) {
    chartData[date] = {
      date: new Date(date),
      assetValue: parsedAssetData[date]?.at(0)?.value || 0,
      liabilityValue: Math.abs(parsedLiabilityData[date][0].value) || 0,
    };
  }
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minWidth={800}
      minHeight={400}
    >
      <AreaChart
        width={width}
        height={height}
        data={Object.values(chartData)}
        margin={margin}
      >
        <defs>
          <linearGradient id="colorAssets" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#065F46" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#065F46" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorLiabilities" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E11D48" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#E11D48" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#333" />
        <XAxis
          dataKey="date"
          tickFormatter={dateFormatter}
          tick={{ fill: "currentColor" }}
        />
        <YAxis
          tickFormatter={formatAsCurrency}
          yAxisId="left"
          tick={{ fill: "currentColor" }}
        />
        {hasLiabilities && (
          <YAxis
            tickFormatter={formatAsCurrency}
            yAxisId="right"
            domain={hasAssets ? [0, 50000] : undefined}
            orientation="right"
          />
        )}
        <Tooltip
          content={
            <CustomAmountTooltip
              dateFormatter={(d) => format(d, "MMM/yyyy")}
              valueFormatter={(d) => (
                <p>
                  {hasAssets && (
                    <>
                      Total assets:&nbsp;
                      <em>{formatAsCurrency(d.assetValue)}</em>
                      <br />
                    </>
                  )}
                  {hasLiabilities && (
                    <>
                      Total liabilities:&nbsp;
                      <em>{formatAsCurrency(d.liabilityValue)}</em>
                    </>
                  )}
                </p>
              )}
            />
          }
        />
        <Legend />
        {hasAssets && (
          <Area
            type="monotone"
            dataKey="assetValue"
            yAxisId="left"
            stroke="#00ff00"
            fillOpacity={1}
            fill="url(#colorAssets)"
            name="Total assets"
          />
        )}
        {hasLiabilities && (
          <Area
            type="monotone"
            dataKey="liabilityValue"
            yAxisId="right"
            stroke="#ff0000"
            fillOpacity={1}
            fill="url(#colorLiabilities)"
            name="Total liabilities"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AccountChart;
