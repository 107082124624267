import * as React from "react";
import DatePicker from "react-datepicker";
import { parse } from "date-fns";

const ExpenseMonthSelector = ({ date }: { date: string }) => {
  return (
    <DatePicker
      selected={parse(date, "yyyy-M", new Date())}
      onChange={(date) =>
        (window.location.href =
          "/expenses?month=" + date.toISOString().slice(0, 7))
      }
      dateFormat="yyyy-MM"
      showMonthYearPicker
    />
  );
};

export default ExpenseMonthSelector;
