import { ExtrudeGeometry, Group, Material, Mesh } from "three";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";
import { ExtrudeGeometryOptions } from "three/src/geometries/ExtrudeGeometry";
import { ShadowMesh } from "three/examples/jsm/objects/ShadowMesh";

function parseSvg(
  contents: string,
  material: Material,
  extrudeSettings: ExtrudeGeometryOptions
): Group {
  const loader = new SVGLoader();
  const group = new Group();
  const data = loader.parse(contents);
  const { paths } = data;
  for (const path of paths) {
    const shapes = SVGLoader.createShapes(path);
    for (const item of shapes) {
      const geometry = new ExtrudeGeometry(item, extrudeSettings);
      const object = new Mesh(geometry, material);
      object.position.set(-140, -150, 0);
      group.add(object);
    }
  }
  return group;
}

export default parseSvg;
