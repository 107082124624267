import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import ReactRailsUJS from "react_ujs";
import Turbolinks from "turbolinks";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

ReactRailsUJS.useContext({
  AccountChart: require("./components/accountChart.tsx"),
  HomeAnimation: require("./components/homeAnimation.tsx"),
  AccountTypePieChart: require("./components/accountTypePieChart.ts"),
  ExpenseMonthSelector: require("./components/expenseMonthSelector.tsx"),
});
