import PieChart from "./pieChart";

const AccountTypePieChart = ({ accountTypes }) => {
  return PieChart({
    data: JSON.parse(accountTypes) as { name: string; value: number }[],
    label: "Percentage by type",
  });
};

export default AccountTypePieChart;
